import { useEffect, useState, useContext, useMemo, memo } from "react";
import { toast } from "react-toastify";
import {
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ButtonGroup,
  Tooltip,
  IconButton,
} from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { ClassicTable } from "../../../utils/components/Styles";
import { showComponent } from "../../helper/helpers";
import { BASE_URL } from "../../../global";

import {
  fetchDescriptionRows,
  useUnitMaster,
  useInstruments,
  useSettings,
} from "./fetchHandlers";
import { RenderMultivalueModal } from "./modals";
import { calFrequencyOptions, calMethodOptions, calPointOptions, ConOfDucdOptions, fixedColumns, locationOptions, modalRequiredFields } from "../../../constants/srfConstants";
import { ImportExcelModal } from "./importExcelModal";
import SrfRowDetailsModal from "./srfRowDetailsModal";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import { SrfContext } from "./srf.context";
import { paratype } from "../../../constants/masterInstrumentConstants";
import DownloadPdfLoaderModal from "../../../utils/components/downloadPdfLoader";

const filterApplyColumn = ['model', 'serialNumber', 'DUCID'];

export const DUCInwardDetails = memo((props) => {
  const {
    id,
    srfInstrumentsToDelete,
    setSrfInstrumentsToDelete,
    srfDetails,
    updateSrfDetails,
    openErrorModal,
  } = props;

  const excelImport = false;

  const [copyQt, setCopyQt] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [multiValueModalOpen, setMultiValueModalOpen] = useState(false);
  const [multiValueData, setMultiValueData] = useState({});
  const [additionalReadingColumns, setAdditionalReadingColumns] = useState([]);

  const [unitArray, unitFilter, setUnitFilter, fetchUnitMaster] = useUnitMaster(
    []
  );
  const [instrumentList, instrumentArray, fetchInstrumentsList, getInstrument] =
    useInstruments([]);
  const [ducId, setDucId] = useState(-1);
  const [openSRFModal, setOpenSRFModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [materialTypeOption, setMaterialTypeOption] = useState([]);
  const [editingRowIndex, setEditingRowIndex] = useState(0);
  const [editAccess, setEditAccess] = useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const historyData = JSON.parse(
    sessionStorage.getItem("historicalTable")
  ) || false;

  // SRF context
  const srfContextValue = useContext(SrfContext);
  const {
    state: { settings, ducInwardRows, loadingDUCInward, },
    dispatch,
  } = srfContextValue;

  const handleOpen = () => setModalOpen(true);
   // column filter logic
   const [filters, setFilters] = useState(
    filterApplyColumn.reduce(
      (acc, field) => {
        acc.visibility[field] = false;
        acc.searchValues[field] = "";
        return acc;
      },
      { visibility: {}, searchValues: {} }
    )
  );
  // Toggle filter visibility
  const toggleVisibility = (field) =>
    setFilters((prev) => ({
      ...prev,
      visibility: { ...prev.visibility, [field]: !prev.visibility[field] },
    }));
  // Update search value
  const updateSearchValue = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      searchValues: { ...prev.searchValues, [field]: value },
    }));
  };
  // Clear search value
  const clearSearchValue = (field) => updateSearchValue(field, "");

  const updateCellValue = (rowIndex, key, value) => {
    const newRows = [...ducInwardRows];
    newRows[rowIndex][key] = value;
    dispatch({
      field: "ducInwardRows",
      value: [...newRows],
    });
  };

  const openDucModalEdit = (isOpen, rowIndex, row) => {
    setOpenSRFModal(isOpen);
    setSelectedRowIndex(rowIndex);
    setSelectedRow(row);
  };

  const addDescriptionRow = () => {
    let row = {
      srfsId: id,
    };
    for (let i = 0; i < fixedColumns.length; i++) {
      if (["id"].includes(fixedColumns[i].key)) {
        row[fixedColumns[i].key] = 0;
      } else {
        row[fixedColumns[i].key] = "";
      }
    }

    row["extraColumns"] = {};
    for (let i = 0; i < additionalReadingColumns.length; i++) {
      row["extraColumns"][additionalReadingColumns[i].key] = "";
    }
    
    openDucModalEdit(true, ducInwardRows.length, row);    
    dispatch({
      field: "ducInwardRows",
      value: [...ducInwardRows, row],
    });
    setEditingRowIndex(ducInwardRows.length);
    setCopyQt([...copyQt, 1]);
  };

  const deleteDescriptionRow = (rowIndex) => {
    let newReadingRows = [...ducInwardRows];
    setSrfInstrumentsToDelete([
      ...srfInstrumentsToDelete,
      newReadingRows[rowIndex].id,
    ]);
    newReadingRows.splice(rowIndex, 1);
    dispatch({
      field: "ducInwardRows",
      value: [...newReadingRows],
    });
    let tmp = [...copyQt];
    tmp.splice(rowIndex, 1);
    setCopyQt([...tmp]);
  };

  const copyDescriptionRow = (rowIndex) => {
    let newReadingRows = [...ducInwardRows];
    let newRows = [...Array(copyQt[rowIndex])].fill(0).map((e) => {
      return { ...newReadingRows[rowIndex], id: 0 };
    });
    newReadingRows.splice(rowIndex + 1, 0, ...newRows);
    dispatch({
      field: "ducInwardRows",
      value: [...newReadingRows],
    });

    let tmp = [...copyQt];
    tmp.splice(
      rowIndex + 1,
      0,
      ...Array(copyQt[rowIndex])
        .fill(0)
        .map((e) => 1)
    );
    tmp[rowIndex] = 1;
    setCopyQt(tmp);
  };

  const checkDucMasterRecord = (id, sr, di, index) => {
    let DUCID = encodeURIComponent(di).replace(/%2C/g, ",");

    // TODO: is exipred logic applicable here too?
    let url = BASE_URL;
    let where = `_where=(instrumentId,eq,${id})`;
    if (sr)
      where +=
        where.length > 0
          ? `~and(serialNumber,eq,${sr})`
          : `(serialNumber,eq,${sr})`;
    if (DUCID)
      where +=
        where.length > 0 ? `~and(DUCID,eq,${DUCID})` : `(DUCID,eq,${DUCID})`;
    let limit = where.length > 0 ? "&_" : "";
    limit += "sort=-id&_size=1";
    axiosWithToken
      .get(url + `ducMaster?${where}${limit}`)
      .then((res) => {
        if (res.data.length === 0) {
          return;
        }
        let row = ducInwardRows[index];
        // TODO: reset data if record not found

        row = {
          ...row,
          DUCID: res.data[0].DUCID,
          serialNumber: res.data[0].serialNumber,
          ranges: res.data[0].ranges,
          lc: res.data[0].lc,
          make: res.data[0].make,
          model: res.data[0].model,
          calFrequency: res.data[0].calFrequency,
          accuracy: res.data[0].accuracy,
          locationOfInstrument: res.data[0].locationOfInstrument,
          department: res.data[0].department,
          operatingRange: res.data[0].operatingRange,
          ducDensity: "",
        };

        if (res?.data[0]?.extraColumns !== null && res?.data[0]?.extraColumns) {
          row.extraColumns = JSON.parse(res?.data[0]?.extraColumns);
        }

        let newRows = [...ducInwardRows];
        newRows[index] = row;

         // Display confirmation dialog
      const confirmAction = window.confirm("DUCID record found. Click OK to continue.");

    if(confirmAction){
        dispatch({
          field: "ducInwardRows",
          value: [...newRows],
        });
      }
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "checkDucMasterRecord",
                  "DucInwardDetails"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };


  const checkLatestInstrumentRecord = (id, sr, di, index, clientId) => {
    let DUCID = encodeURIComponent(di).replace(/%2C/g, ",");
    let url = BASE_URL;
    let where = `where=(ins.instrumentId,eq,${id})`;
    if (sr)
      where +=
        where.length > 0
          ? `~and(ins.serialNumber,eq,${sr})`
          : `(ins.serialNumber,eq,${sr})`;
    if (DUCID)
      where +=
        where.length > 0
          ? `~and(ins.DUCID,eq,${DUCID})`
          : `(ins.DUCID,eq,${DUCID})`;

    if (clientId)
            where +=
              where.length > 0
                ? `~and(srf.clientId,eq,${clientId})`
                : `(srf.clientId,eq,${clientId})`;
                

    let limit = where.length > 0 ? "&_" : "";
    limit += "sort=-ins.id&_size=1";
    axiosWithToken
      .get(
        url +
          `xjoin?_join=ds.datasheets,_j,ins.srfInstruments,_j,srf.srfs&_on1=(ds.id,eq,ins.id)&_on2=(srf.id,eq,ins.srfsId)&_${where}${limit}&_fields=ins.instrumentId,ins.DUCID,ins.serialNumber,ins.ranges,ins.lc,ins.make,ins.model,ins.operatingRange,ins.department,ins.ConOfDuc,ins.calFrequency,ins.accuracy,ins.location,ins.locationOfInstrument,ins.calPoint,ins.calMethod,ins.labCapabilities,ins.sensorType,ins.ducDensity,ins.extraColumns,ds.nextDueDate,ds.id,ins.id,srf.serviceReqNumber`
      )
      .then((res) => {
        if (res.data.length === 0) {
          return;
        }
        // setSrfInstruments(res.data);
        let row = [];
        if (res.data.length === 1) {
          let expired = new Date(res.data[0].ds_nextDueDate) > new Date();
          row = {
            id: 0,
            instrumentId: res.data[0].ins_instrumentId,
            DUCID: res.data[0].ins_DUCID,
            serialNumber: res.data[0].ins_serialNumber,
            ranges: res.data[0].ins_ranges,
            lc: res.data[0].ins_lc,
            make: res.data[0].ins_make,
            model: res.data[0].ins_model,
            ConOfDuc: res.data[0].ins_ConOfDuc,
            calFrequency: res.data[0].ins_calFrequency,
            accuracy: res.data[0].ins_accuracy,
            location: res.data[0].ins_location,
            locationOfInstrument: res.data[0].ins_locationOfInstrument,
            calPoint: res.data[0].ins_calPoint,
            calMethod: res.data[0].ins_calMethod,
            materialType: res.data[0].ins_materialType,
            department: res.data[0].ins_department,
            operatingRange: res.data[0].ins_operatingRange,
            sensorType: res.data[0].ins_sensorType,
            ducDensity: res.data[0].ins_ducDensity ? res.data[0].ins_ducDensity : "",
            labCapabilities: res.data[0].ins_labCapabilities,
          };
          // let extraColumns = JSON.parse(res.data[0].ins_extraColumns || "{}");
          let additionalColumns =
            settings?.srfInstrumentsAdditionalColumns?.value?.split(",");
          let resposeExtraColumns = JSON.parse(
            res.data[0].ins_extraColumns || "{}"
          );

          let extraColumns = {};

          if (additionalColumns) {
            additionalColumns.forEach((column) => {
              let trimmedColumn = column; // Remove any leading/trailing spaces use (.trim())
              if (resposeExtraColumns.hasOwnProperty(trimmedColumn)) {
                extraColumns[trimmedColumn] =
                  resposeExtraColumns[trimmedColumn];
              } else {
                extraColumns[trimmedColumn] = ""; // Add the key with an empty string value
              }
            });
          }

          let newRows = [...ducInwardRows];
          newRows[index] = {
            ...newRows[index],
            ...row,
            extraColumns: {
              ...newRows[index].extraColumns,
              ...extraColumns,
            },
          };
           // Display confirmation dialog
      const confirmAction = window.confirm("DUCID record found. Click OK to continue.");
      if(confirmAction){
          dispatch({
            field: "ducInwardRows",
            value: newRows,
          });
        };
          if (expired) {
            setDucId([res.data[0].ins_DUCID, ducId[1]]);
            setTimeout(() => handleOpen(), 250);
          }
        }
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "checkLatestInstrumentRecord",
                  "DucInwardDetails"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  useEffect(() => {
    fetchDescriptionRows(id, dispatch);
    fetchUnitMaster();
  }, []);

  useEffect(() => {
    // update materialTypeOptions state after data fetch
    if (!settings?.materialType) {
      setMaterialTypeOption([]);
    } else {
      const options = settings.materialType.value.split(",").map((option) => {
        const [label, value] = option.split(":");
        return { label, value };
      });
      setMaterialTypeOption(options);
    }
  }, [settings?.materialType]);

  useEffect(() => {
    if (settings.srfInstrumentsAdditionalColumns?.value) {
      let additionalReadingColumns = [];
      settings.srfInstrumentsAdditionalColumns?.value
        ?.split(",")
        .forEach((col) => {
          additionalReadingColumns.push({
            field: col,
            key: col,
            headerName: col,
            editable: true,
          });
        });
      setAdditionalReadingColumns([...additionalReadingColumns]);
    }
  }, [settings?.srfInstrumentsAdditionalColumns?.value]);

  useEffect(() => {
    if (settings.DUCMasterStatus?.value === "true") {
      fetchInstrumentsList({
        filterWithDucMaster: true,
        clientId: srfDetails?.clientId,
      });
    } else {
      fetchInstrumentsList({});
    }
  }, [settings.DUCMasterStatus?.value, srfDetails?.clientId]);

  const renderSRFRow = (rowIndex, row) => {
    return (
      <>
        {fixedColumns.map((column, cellIndex) => {
          const key = column.key;
          if (["id"].includes(key) && !openSRFModal) {
            return (
              <TableCell
                style={{ minHeight: "58px", border: openSRFModal ? "none" : "1px solid #ddd", padding : openSRFModal ? "0px" : "10px", }}
                key={"ducrow-" + rowIndex + "-" + cellIndex}
              >
                {rowIndex + 1}
              </TableCell>
            );
          } else if (
            [
              "requestedDucName",
              "DUCID",
              "serialNumber",
              "make",
              "model",
              "locationOfInstrument",
              "labCapabilities",
              "department",
            ].includes(key)
          ) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px", border: openSRFModal ? "none" : "1px solid #ddd", padding : openSRFModal ? "0px" : "10px", }}
                key={"ducrow-" + rowIndex + "-" + cellIndex}
              >
                <TextField
                  id={`editsrf-ducInwardDetails-${key}`}
                  key={row[key]}
                  label={openSRFModal ? `${key}${modalRequiredFields.includes(key) ? ' *' : ''}` : ""}
                  style={{ width: "200px" }}
                  size="small"
                  inputProps={{
                    autoComplete: 'off',
                  }}
                  defaultValue={row[key]}
                  onBlur={(e) => {
                    updateCellValue(rowIndex, key, e.target.value);
                    let serialNumber =
                      key === "serialNumber"
                        ? e.target.value
                        : row["serialNumber"];
                    let DUCID = key === "DUCID" ? e.target.value : row["DUCID"];
                    if (
                      row.id < 1 &&
                      !excelImport &&
                      e.target.value.length > 0 &&
                      typeof row["instrumentId"] === "number" &&
                      ["DUCID", "serialNumber"].includes(key)
                    ) {
                      if (settings?.DUCMasterStatus?.value === "true") {
                        checkDucMasterRecord(
                          row["instrumentId"],
                          serialNumber,
                          DUCID,
                          rowIndex
                        );
                      } else {
                        checkLatestInstrumentRecord(
                          ducInwardRows[rowIndex]["instrumentId"],
                          serialNumber,
                          DUCID,
                          rowIndex,
                          srfDetails?.clientId
                        );
                      }
                    }
                  }}
                ></TextField>
              </TableCell>
            );
          } else if (["instrumentId"].includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px", border: openSRFModal ? "none" : "1px solid #ddd", padding : openSRFModal ? "0px" : "10px", }}
                key={"ducrow-" + rowIndex + "-" + cellIndex}
                // style={{ width: "200px" }}
                id="editsrf-ducInwardDetails-instrumentId"
              >
                <Autocomplete
                  style={{ width: "200px" }}
                  defaultValue={getInstrument(row[key])}
                  disabled={historyData}
                  size="small"
                  id="combo-box-demo"
                  value={getInstrument(row[key])}
                  options={instrumentList}
                  renderInput={(params) => <TextField {...params} label={openSRFModal ? "Select Instrument *" : ""} />}
                  onInputChange={(event, newInputValue) => {
                    var splArr = newInputValue.split(",");
                    updateCellValue(rowIndex, key, parseInt(splArr[0]));
                    setDucId([ducId[0], splArr[1]]);
                    if (
                      row.id < 1 &&
                      !excelImport &&
                      typeof row["instrumentId"] === "number" &&
                      ["DUCID", "serialNumber"].includes(key)
                    ) {
                      if (settings?.DUCMasterStatus?.value === "true") {
                        checkDucMasterRecord(
                          parseInt(splArr[0]),
                          row["serialNumber"],
                          row["DUCID"],
                          rowIndex
                        );
                      } else {
                        checkLatestInstrumentRecord(
                          parseInt(splArr[0]),
                          row["serialNumber"],
                          row["DUCID"],
                          rowIndex,
                          srfDetails?.clientId
                        );
                      }
                    }

                    for (let i = 0; i < instrumentArray.length; i++) {
                      if (
                        parseInt(newInputValue.split(",")[0]) ==
                        instrumentArray[i].id
                      ) {
                        updateSrfDetails({
                          ...srfDetails,
                          discipline: instrumentArray[i].name,
                          calibrationProcedureNo:
                            instrumentArray[i].calibrationProcedureNo,
                        }); 
                      }
                    }

                    // Adding unit without filtration because I am filtering unit by disciplineId and row in (RenderMultivalueModal)
                    setUnitFilter(unitArray);
                  }}
                />
              </TableCell>
            );
          } else if (
            ["ranges", "operatingRange", "lc", "accuracy","ducDensity"].includes(key)
          ) {
            return (
              <TableCell
                style={{ minWidth: openSRFModal ? "190px" : "180px", minHeight: "58px", border: openSRFModal ? "none" : "1px solid #ddd", padding : openSRFModal ? "0px" : "10px", }}
                key={"ducrow-" + rowIndex + "-" + cellIndex}
              >
                <Button
                  id={`editsrf-ducInwardDetails-${key}`}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setMultiValueData({
                      rowIndex: rowIndex,
                      key: key,
                    });
                    setMultiValueModalOpen(!multiValueModalOpen);
                  }}
                  style={{
                    backgroundColor:
                      row[key] && row[key].length > 0 ? "#d9fdd3" : "",
                      width: openSRFModal ? "100%" : "auto",
                  }}
                >
                  {openSRFModal ? `Add ${key}${modalRequiredFields.includes(key) ? ' *' : ''}` : "Add"}
                </Button>
              </TableCell>
            );
          } else if ("calFrequency".includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px", border: openSRFModal ? "none" : "1px solid #ddd", padding : openSRFModal ? "0px" : "10px", }}
                key={"ducrow-" + rowIndex + "-" + cellIndex}
              >
                <Grid item xs={3} id="editsrf-ducInwardDetails-calFrequency">
                  <Autocomplete
                    style={{ width: "200px" }}
                    size="small"
                    id="combo-box-demo"
                    value={row[key]}
                    options={calFrequencyOptions}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => <TextField {...params} label={openSRFModal ? `${key} *` : ""} />}
                    onInputChange={(event, newInputValue) => {
                      const isValid = calFrequencyOptions.some(option => option.label === newInputValue);
                      updateCellValue(rowIndex, key, isValid ? newInputValue : null);
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else if (["location"].includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px", border: openSRFModal ? "none" : "1px solid #ddd", padding : openSRFModal ? "0px" : "10px", }}
                key={"ducrow-" + rowIndex + "-" + cellIndex}
              >
                <Grid item xs={3} id="editsrf-ducInwardDetails-location">
                  <Autocomplete
                    style={{ width: "200px" }}
                    value={row[key] != "2" ? "In house" : "Onsite" || "In house"}
                    size="small"
                    id="combo-box-demo"
                    options={locationOptions}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => <TextField {...params} label={openSRFModal ? `${key} *` : ""} />}
                    onInputChange={(event, newInputValue) => {
                      updateCellValue(rowIndex, key, newInputValue == "In house" ? "1" : "2");
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else if (["calPoint"].includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px", border: openSRFModal ? "none" : "1px solid #ddd", padding : openSRFModal ? "0px" : "10px", }}
                key={"ducrow-" + rowIndex + "-" + cellIndex}
              >
                <Grid item xs={3} id="editsrf-ducInwardDetails-calPoint">
                  <Autocomplete
                    style={{ width: "200px" }}
                    size="small"
                    id="combo-box-demo"
                    value={row[key] || "As per procedure" } 
                    options={calPointOptions}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => <TextField {...params} label={openSRFModal ? key : ""} />}
                    onInputChange={(event, newInputValue) => {
                      const isValid = calPointOptions.some(option => option.label === newInputValue);
                      updateCellValue(rowIndex, key, isValid ? newInputValue : null);
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else if (["calMethod"].includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px", border: openSRFModal ? "none" : "1px solid #ddd", padding : openSRFModal ? "0px" : "10px", }}
                key={"ducrow-" + rowIndex + "-" + cellIndex}
              >
                <Grid item xs={3} id="editsrf-ducInwardDetails-calMethod">
                  <Autocomplete
                    style={{ width: "200px" }}
                    size="small"
                    id="combo-box-demo"
                    value={row[key]}
                    options={calMethodOptions}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => <TextField {...params} label={openSRFModal ? key : ""} />}
                    onInputChange={(event, newInputValue) => {
                      const isValid = calMethodOptions.some(option => option.label === newInputValue);
                      updateCellValue(rowIndex, key, isValid ? newInputValue : "");
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else if (["ConOfDuc"].includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px", border: openSRFModal ? "none" : "1px solid #ddd", padding : openSRFModal ? "0px" : "10px", }}
                key={"ducrow-" + rowIndex + "-" + cellIndex}
              >
                <Grid item xs={3} id="editsrf-ducInwardDetails-calMethod">
                  <Autocomplete
                    style={{ width: "200px" }}
                    size="small"
                    id="combo-box-demo"
                    value={row[key] || "Satisfactory"}
                    options={ConOfDucdOptions}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => <TextField {...params} label={openSRFModal ? key : ""} />}
                    onInputChange={(event, newInputValue) => {
                      const isValid = ConOfDucdOptions.some(option => option.label === newInputValue);
                      updateCellValue(rowIndex, key, isValid ? newInputValue : null);
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else if (["materialType"].includes(key)) {

            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px", border: openSRFModal ? "none" : "1px solid #ddd", padding : openSRFModal ? "0px" : "10px", }}
                key={"ducrow-" + rowIndex + "-" + cellIndex}
              >
                <Grid item xs={3} id="editsrf-ducInwardDetails-materialType">
                  <Autocomplete
                    style={{ width: "200px" }}
                    size="small"
                    key={row[key]}
                    id="combo-box-demo"
                    value={
                      row[key] !== undefined && row[key] !== ""
                        ? materialTypeOption.find(option => option.value === row[key]) || ""
                        : materialTypeOption[0]?.label || "" 
                    }
                    isOptionEqualToValue={(option, value) => value}
                    options={materialTypeOption.map((option) => option.label)}
                    renderInput={(params) => <TextField {...params} label={openSRFModal ? key : ""} />}
                    onInputChange={(event, newInputValue) => {
                      const selectedOption = materialTypeOption.find(
                        (option) => option.label === newInputValue
                      );
                      if (selectedOption) {
                        updateCellValue(rowIndex, key, selectedOption.value);
                      }
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else if (["sensorType"].includes(key)) {
            return (
              <TableCell
                style={{ minWidth: "180px", minHeight: "58px", border: openSRFModal ? "none" : "1px solid #ddd", padding : openSRFModal ? "0px" : "10px", }}
                key={"sensorType-" + rowIndex + "-" + cellIndex}
              >
                <Grid item xs={3} id="editsrf-ducInwardDetails-sensorType">
                  <Autocomplete
                    style={{ width: "200px" }}
                    size="small"
                    id="combo-box-demo"
                    value={row[key]}
                    options={paratype.Measure?.["Temperature Simulation"]?.map((label, index) => ({
                      id: index + 1,
                      label,
                    }))}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={(params) => <TextField {...params} label={openSRFModal ? key : ""} />}
                    onInputChange={(event, newInputValue) => {
                      const isValid = paratype.Measure?.["Temperature Simulation"]?.some(option => option === newInputValue);
                      updateCellValue(rowIndex, key, isValid ? newInputValue : "");
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else {
            return null;
          }
        })}
        {Object.keys(row["extraColumns"] || {}).map((extraColumnKey, index) => {
          return (
            <TableCell
              style={{ minWidth: "180px", minHeight: "58px", border: openSRFModal ? "none" : "1px solid #ddd", padding : openSRFModal ? "0px" : "10px", }}
              key={"ducrow-" + rowIndex + "-" + index}
            >
              <TextField
                style={{ width: "200px" }}
                size="small"
                inputProps={{
                  autoComplete: 'off',
                }}
                label={openSRFModal ? extraColumnKey : ""}
                value={row["extraColumns"][extraColumnKey]}
                onChange={(e) => {
                  updateCellValue(rowIndex, "extraColumns", {
                    ...row["extraColumns"],
                    [extraColumnKey]: e.target.value,
                  });
                }}
              ></TextField>
            </TableCell>
          );
        })}
      </>
    );
  };

  const renderSRFRowViewMode = (rowIndex, row) => {
    let rowView = [];
    let extraColumns = Object.keys(row["extraColumns"] || {});
    for (let i = 0; i < fixedColumns.length; i++) {
      // 1. convert columns values in redable mode
      const key = fixedColumns[i]?.["key"];
      let value = row[key];
      if (key == "id") {
        value = rowIndex + 1;
      }
      if (key == "instrumentId") {
        value = getInstrument(row[key]);
        value = value?.label;
      } else if (key == "materialType") {
        value = materialTypeOption?.find(
          (option) => option.value == row[key]
        )?.label || "";
      } else if (
        key == "ranges" ||
        key == "lc" ||
        key == "operatingRange" ||
        key == "accuracy" ||
        key == "ducDensity"
      ) {
        value = value
          ?.replaceAll("||", ", ")
          ?.replaceAll("|", " to ")
          ?.replaceAll("#", " ");
        if (value?.length > 50) {
          value = value?.substring(0, 50) + "...";
        }
      } else if (key == "location") {
        value = value == 1 ? "In House" :"Onsite" ;
      }

      rowView.push(
        <TableCell
          key={ rowIndex + "-fc-" + i}
          onClick={() => {
            setEditingRowIndex(rowIndex);
          }}
        >
          <span>{value}</span>
        </TableCell>
      );
    }
    if (extraColumns.length > 0) {
      for (let i = 0; i < extraColumns.length; i++) {
        const extraColumnKey = extraColumns[i];
        rowView.push(
          <TableCell
            key={rowIndex + "-ec-" + i}
            onClick={() => {
              setEditingRowIndex(rowIndex);
            }}
          >
            <span>{row["extraColumns"][extraColumnKey]}</span>
          </TableCell>
        );
      }
    }
    return rowView;
  };
 
  
  const renderDUCInwardRows = () => {
    if (!fixedColumns || !settings || !ducId  )
      return <TableRow>Loading...</TableRow>;

    return ducInwardRows.map((row, rowIndex) => {
      // Apply filtering 
      let match = true;
      Object.keys(filters.searchValues).forEach((field) => {
        if (filters.visibility[field]) {
          const value = row[field]?.toString().toLowerCase() || "";
          if (!value.includes(filters.searchValues[field].toLowerCase())) {
            match = false;
          }
        }
      });
      if (!match) return null; // Skip rows if doesn't match filter
    return  (
      <TableRow key={rowIndex}>
        <TableCell>
          <ButtonGroup size="small" aria-label="small button group" style={{gap:"1px", alignItems:"center"}}>
            <TextField
              style={{
                width: "50px",
                marginRight: "10px",
              }}
              variant="standard"
              type="number"
              // inputProps={{ min: 1, max: 75 }}
              InputProps={{ inputProps: { min: 1, max: 75 } }}
              size="small"
              defaultValue={copyQt[rowIndex] || 1}
              onChange={(e) => {
                let tmp = [...copyQt];
                tmp[rowIndex] = Number(e.target.value);
                setCopyQt(tmp);
              }}
            ></TextField>
            <Tooltip title="Copy as New Row" placement="top-start"> 
              <Button
               disabled={historyData}
              >
              <ContentCopyIcon
                id="editsrf-ducInwardDetails-copybtn"
                style={{ 
                  cursor: historyData || !(editAccess?.includes(5) || editAccess?.includes(0)) ? 'not-allowed' : 'pointer', 
                  color: historyData || !(editAccess?.includes(5) || editAccess?.includes(0)) ? 'lightgray' : '#1976d2' 
                }}
                
                disabled={historyData}
                onClick={(e) => {
                  if (!(editAccess?.includes(5) || editAccess?.includes(0))) {
                    return;
                  }
                  const confirmed = window.confirm(
                    "Are you sure you want to copy this row?"
                  );
                  if (confirmed) {
                    copyDescriptionRow(rowIndex);
                  }
                }}
              />
               </Button>
            </Tooltip>
       
            {/* {isMobile && ( */}
            <Tooltip title="Open In Modal" placement="top-start">
              <PreviewIcon
                style={{ color: "#1976d2", cursor:"pointer" }}
                onClick={(e) =>  openDucModalEdit(true, rowIndex, row)}
              />
            </Tooltip>
            {/* )} */}

            {showComponent("delete_in") && (
              <Tooltip title="Delete Row" placement="top-start">
                <DeleteIcon
                  id="editsrf-ducInwardDetails-deltebtn"
                  style={{ cursor: "pointer", color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }}
                  onClick={(e) => {
                    if (!(editAccess?.includes(2) || editAccess?.includes(0))) {
                      return;
                    }
                    const confirmed = window.confirm(
                      "Are you sure you want to delete this row?"
                    );
                    if (confirmed) {
                      deleteDescriptionRow(rowIndex);
                    }
                  }}
                />
              </Tooltip>
            )}
          </ButtonGroup>
        </TableCell>
        {/* {renderSRFRow(rowIndex, row)} */}
        {editingRowIndex !== rowIndex && renderSRFRowViewMode(rowIndex, row)}
        {editingRowIndex == rowIndex && renderSRFRow(rowIndex, row)}
      </TableRow>
     );
    })
    .filter(row => row !== null); // Remove any null entries
};

  return (
    <div id="editsrf-ducInwardDetails">
      <h4 style={{ marginBottom: "0px", float: "left" }}>DUC Inward Details</h4>
      <br /> <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
          {showComponent("add_in")  && (
        <Button
          id="editsrf-ducInwardDetails-addButton"
          size="small"
          disabled={historyData}
          variant="contained"
          onClick={(e) => {
            addDescriptionRow();
          }}
        >
          to Add DUC
        </Button>
      )}


        {showComponent("add_in") && (
          <div id="editsrf-ducInwardDetails-uploadexcel">
            <ImportExcelModal
              fixedColumns={fixedColumns}
              // setReadingRows={(ducInwardRows) => {
              //   dispatch({
              //     field: "ducInwardRows",
              //     value: ducInwardRows,
              //   });
              // }}
              
              settings={settings}
              isSRF={true}
            />
          </div>
        )}
      </div>
      <div style={{ width: "100%", overflow: "auto" , height:ducInwardRows?.length > 10 ? "85vh" : "auto" }}>
        <ClassicTable>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                {fixedColumns.map((column) => (
                  <TableCell key={column.field}>
                   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography noWrap>{column.headerName}</Typography>
                  {id != 0 && filterApplyColumn.includes(column.field) && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        size="small"
                        onClick={() => toggleVisibility(column.field)}
                        aria-label={`filter ${column.field}`}
                      >
                        <FilterAltIcon />
                      </IconButton>
                      {filters.visibility[column.field] && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <TextField
                            size="small"
                            variant="standard"
                            placeholder={`Search ${column.headerName}`}
                            value={filters.searchValues[column.field]}
                            onChange={(e) => updateSearchValue(column.field, e.target.value)}
                            style={{ marginLeft: '5px', marginTop: '5px' }}
                          />
                          {filters.searchValues[column.field] && (
                            <IconButton
                              size="small"
                              onClick={() =>clearSearchValue(column.field)}
                              aria-label={`clear ${column.field} filter`}
                              style={{ marginLeft: '5px' }}
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                  </TableCell>
                ))}
                {additionalReadingColumns.map((column) => (
                  <TableCell key={column.field}>
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{renderDUCInwardRows()}</TableBody>
          </Table>
        </ClassicTable>
        
      </div>

      {selectedRow && (
        <SrfRowDetailsModal
          open={openSRFModal}
          handleClose={() => setOpenSRFModal(false)}
          rowIndex={selectedRowIndex}
          row={selectedRow}
          fixedColumns={fixedColumns}
          updateCellValue={updateCellValue}
          setMultiValueData={setMultiValueData}
          setMultiValueModalOpen={setMultiValueModalOpen}
          instrumentList={instrumentList}
          getInstrument={getInstrument}
          set_DUIC_ID={setDucId}
          updateSrfDetails={updateSrfDetails}
          setUnitFilter={setUnitFilter}
          excelImport={excelImport}
          settings={settings}
          DUC_ID={ducId}
          checkDucMasterRecord={checkDucMasterRecord}
          checkLatestInstrumentRecord={checkLatestInstrumentRecord}
          additionalReadingColumns={additionalReadingColumns}
          renderSRFRow={renderSRFRow}
        />
      )}

      {
        <RenderMultivalueModal
          multiValueData={multiValueData}
          multiValueModalOpen={multiValueModalOpen}
          setMultiValueModalOpen={setMultiValueModalOpen}
          readingRows={ducInwardRows}
          updateCellValue={updateCellValue}
          unitFilter={unitFilter}
          instrumentArray={instrumentArray}
          setOpenSRFModal={setOpenSRFModal}
        />
      }

{loadingDUCInward && (
        <DownloadPdfLoaderModal
          loading={loadingDUCInward}
          name={"DUC Inward"}
        />
      )}

    </div>
  );
});
